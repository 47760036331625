/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import { loc } from '../../../../../../lib/data';
import { Modal } from '../../../../../../lib/components/modal';
import { useState } from 'preact/hooks';

export const SelectLocModal = ({
  onClose,
  onSelect,
  title,
  locs
}: {
  onClose: () => void;
  onSelect: (id: string) => void;
  title: string;
  locs: loc[];
}): JSX.Element => {
  const [selected, setSelected] = useState<string>('');

  return (
    // eslint-disable-next-line @typescript-eslint/require-await
    <Modal closeFunc={async () => onClose()} canExit={true}>
      <div id='assignModal'>
        <h3>{title}</h3>
        <div id='statusInputWrapper'>
          <select onChange={(e) => setSelected(e.currentTarget.value)}>
            <option disabled selected value=''>
              [Select an LOC]
            </option>
            {locs.map((thisLoc) =>
              <option key={thisLoc.id} value={thisLoc.id}>{thisLoc.name}</option>)}
          </select>
        </div>
      </div>
      <div id='codesButtons' className='hideOnPrint topPadding'>
        <button id='completeButton' className='secondaryButton' onClick={onClose}>
            Cancel
        </button>
        <button
          disabled={selected === ''}
          className='primaryButton'
          onClick={() => onSelect(selected)}
        >
            Save changes
        </button>
      </div>
    </Modal>
  );
};
