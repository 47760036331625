/* eslint-disable react/react-in-jsx-scope */
import {
  useContext,
  useState
} from 'preact/hooks';
import {
  Fragment,
  h
} from 'preact';
import * as emailValidator from 'email-validator';

import { AccountPasswordInformation } from '../../../../../../lib/components/AccountPasswordInformation';
import { BackupCodesButton } from '../../../../../../lib/components/BackupCodesButton';
import { DlocClientContext } from '../../components/DlocClientContext';
import { useContactInfo } from '../../dlocData';

import './styles.scss';
import { Loader } from '../../../../../../lib/components/Loader';
import { AccountRecoveryData } from '../../../../../../lib/components/AccountRecoveryData';

const ProfileInformation = () => {
  const { contact, setContactInfo } = useContactInfo();
  const [editState, setEditState] = useState({
    name: '',
    email: '',
  });
  const [showSpinner, setSpinner] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [changingProfile, setChangingProfile] = useState(false);

  const saveContactInformation = () => {
    if (!emailValidator.validate(editState.email) || editState.email === '') {
      setErrorString('Please enter a valid email');
      return;
    }

    if (editState.name === '') {
      setErrorString('Please enter a name');
      return;
    }

    setErrorString('');

    setSpinner(true);

    setContactInfo(editState)
      .then(() => {
        setChangingProfile(false);
      })
      .catch((error) => {
        setErrorString((error as Error).message);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const openEdit = () => {
    setEditState({
      name: contact.name,
      email: contact.email,
    });
    setChangingProfile(true);
  };

  if (!changingProfile) {
    return (
      <Fragment>
        <div id="piLeft">
          <h2>Profile Information</h2>
          <label>
            Username:
            <input
              disabled
              type="text"
              name="username"
              value={contact.username}
            />
          </label>
          <label>
            Contact Name:
            <input
              disabled
              type="text"
              name="contact-name"
              value={contact.name}
            />
          </label>
          <label htmlFor="">
            Contact Email:
            <input
              disabled
              type="text"
              name="contact-email"
              value={contact.email}
            />
          </label>
        </div>
        <div id="piRight">
          <button
            type="button"
            className="secondaryButton desktopOnly"
            onClick={openEdit}
          >
            Update Contact Information
          </button>
          <img
            className="mobileOnly"
            src={require('./edit.svg') as string}
            alt="edit pencil"
            onClick={openEdit}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div id="newContact">
        <div id="piLeft">
          <h2>Profile Information</h2>
          <label id="uname">
            Username:
            <input
              disabled
              type="text"
              name="username"
              value={contact.username}
              autoComplete="false"
            />
          </label>
          <label className="newContactInput">
            Contact Name:
            <br />
            <input
              className="newInput"
              type="text"
              name="contact-name"
              value={editState.name}
              onChange={(evt) =>
                setEditState({ ...editState, name: evt.currentTarget.value })
              }
              autoComplete="false"
            />
          </label>
          <label className="newContactInput">
            Contact Email:
            <br />
            <input
              className="newInput"
              type="text"
              name="contact-email"
              value={editState.email}
              onChange={(evt) =>
                setEditState({ ...editState, email: evt.currentTarget.value })
              }
              autoComplete="false"
            />
          </label>
          <p>NOTE: Updating your email address may take up to a minute.</p>
          <p
            className='error'
            dangerouslySetInnerHTML={{ __html: errorString }}
          />
        </div>
        <div id="piRight">
          <button
            type="button"
            className="blankButton"
            onClick={() => setChangingProfile(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="primaryButton"
            onClick={saveContactInformation}
          >
            Save Changes
          </button>
        </div>
        <Loader show={showSpinner} />
      </div>
    </Fragment>
  );
};

// eslint-disable-next-line no-empty-pattern
export const AccountSettingsPage = ({}: { path?: string }): h.JSX.Element => {
  const client = useContext(DlocClientContext);

  return (
    <Fragment>
      <main id="accountSettings">
        <div id="contentContainer">
          <header>
            <h1>Account Settings</h1>
          </header>
          <section id="profileInfo" className="splitter">
            <ProfileInformation />
          </section>
          <section id="passwordSection" className="splitter">
            <AccountPasswordInformation client={client} />
          </section>
          {client.userData?.marker && (
            <section id="accountRecoverySection" className="splitter">
              <AccountRecoveryData client={client} />
            </section>
          )}
          <div id="deleteWrapper">
            <BackupCodesButton client={client} />
          </div>
        </div>
      </main>
    </Fragment>
  );
};
