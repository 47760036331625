/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';

import './styles.scss';
import { Modal } from '../modal';

export const KeyGenModal = ({
  onClose,
  onAccept
}: {
  onClose: () => void;
  onAccept: () => void;
}): JSX.Element => (
  // eslint-disable-next-line @typescript-eslint/require-await
  <Modal closeFunc={async () => onClose()} canExit={true}>
    <div id="keyGenModal">
      <h2>Generate a New Shared Key</h2>
      <p>It is rare to need to generate a new shared key. Unless there has been a data breach,
      chances are good that you do not need to. Only click the Generate Key button if you are sure
      that you need to do so.</p>
      <div id="buttonDiv" className="topPadding">
        <button className="secondaryButton" onClick={onClose}>Cancel</button>
        <button className="primaryButton" onClick={onAccept}>Generate Key</button>
      </div>
    </div>
  </Modal>
);
