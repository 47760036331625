import {
  crypto_hash_sha256,
  crypto_sign_keypair,
  randombytes_buf,
  to_hex
} from 'libsodium-wrappers-sumo';
import {
  Box,
  KeyGenerationHelper,
  Matching,
  OPAQUE,
  OPRF
} from '../../../lib/crypto';
import { RPCClient } from '../../../lib/rpc';
import { endpoints as v1 } from '../endpoints';
import {
  assignmentData,
  CaseStatus,
  closeOutQuestionnaire,
  dloc,
  dlocEntry,
  dlocUserData,
  identifiedEncryptedNotes
} from '../../../lib/data';
import {
  tAssignmentData,
  tCloseOutQuestionnaire,
  tDlocUserData,
  tEncryptedBox,
  tKey,
  tVersionedKeyBox
} from '../../server/util/iots';
import { LOC } from '../../../lib/models';
import {
  Decoder,
  Encoder,
  string as tstring
} from 'io-ts';
import sha1 from 'js-sha1';
import { RecoveryClient } from '../../recoveryserver/client';

// This is cached here since it never ended up changing between environments.
const commonBadgeSalt = '0ddMh4J8gWtnC4Gt';

export interface NewDlocData {
  name: string;
  email: string;
}

export interface NewLocData {
  name: string;
  email: string;
  practice: string;
  state: string;
}

export interface EntryData {
  entryId: string;
  assignmentData: {
    dlocKeyVersion: number;
    encryptedAssignmentData: Uint8Array;
  };
  questionnaire: null | {
    dlocKeyVersion: null | number;
    encryptedQuestionnaire: Uint8Array;
  };
  dlocNotes: null | {
    dlocKeyVersion: number;
    encryptedDlocNotes: Uint8Array;
  };
  entryKey: null | {
    dlocKeyVersion: number;
    encryptedKey: Uint8Array;
  };
  locNotes: {
    id: string;
    dlocKeyVersion: number;
    encryptedLocNotes: Uint8Array;
  }[];
}

interface ReencryptedEntryData {
  entryId: string;
  encryptedAssignmentData: Uint8Array;
  encryptedQuestionnaire: Uint8Array;
  encryptedDlocNotes: null | Uint8Array;
  encryptedKey: null | Uint8Array;
  locNotes: {
    id: string;
    encryptedLocNotes: Uint8Array;
  }[];
}

export class DLOCClient extends RPCClient {
  public contactEmail = '';
  public contactName = '';
  public token = '';
  public envVars: Record<string, string> = {};
  public userData: dlocUserData = {};

  public versionedKeys: Record <number, {
    publicKey: Uint8Array;
    privateKey: Uint8Array;
  }> = {};

  private recoveryClient: RecoveryClient;

  constructor(baseURL?: string) {
    super();
    if (!baseURL) {
      baseURL = process.env.APP_SERVER_URL;
    }
    // TODO: move this into the base class!
    this.baseURL = baseURL !== undefined ? baseURL : '';
  }

  public async getEnvironmentVariables(): Promise<Record<string, string>> {
    this.envVars = await this.call(v1.GetFrontEndEnvironmentVariables)({});
    return this.envVars;
  }

  // Login =====================================================================
  public async login(
    username: string,
    password: string,
    badgeSalt = commonBadgeSalt
  ): Promise<void> {
    const lowercaseUsername = username.toLowerCase();
    // Try using just the username as the index first.
    // This modification was made to make our instance of OPAQUE more secure
    // against dictionary attacks than the original was.
    const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
    const passwordHash = OPAQUE.makePassword(password, badgeSalt);
    const passwordAlpha = OPAQUE.mask(passwordHash);

    try {
      await this.doLogin(lowercaseUsername, usernameHash, passwordAlpha);
    } catch {
      try {
        // Try to use a lowercase username first, since that will be the case
        // for the majority of users.
        const identity = OPAQUE.makeIdentity(
          lowercaseUsername,
          password,
          badgeSalt
        );
        const identityAlpha = OPAQUE.mask(identity.key);
        await this.doLogin(lowercaseUsername, identity.index, identityAlpha);
      } catch {
        // Failing that, try the original provided username.
        const identity = OPAQUE.makeIdentity(username, password, badgeSalt);
        const identityAlpha = OPAQUE.mask(identity.key);
        await this.doLogin(username, identity.index, identityAlpha);
      }

      // We need to upgrade the index to match the new method so we can skip
      // these old index styles.
      const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
        alpha: passwordAlpha.point,
      });
      const unmasked = OPAQUE.unmask(beta, passwordAlpha.mask);
      const encrypted = OPAQUE.encrypt(unmasked, {
        pk: this.publicKey,
        sk: this.privateKey,
      });

      await this.encryptedCall(v1.UpdatePassword)({
        newIndex: usernameHash,
        newEnvelope: encrypted,
      });
    }

    await this.bootstrap();
  }

  public async saveUserData(): Promise<unknown> {
    return this.encryptedCall(v1.SaveUserData)({
      data: Box.tsEncrypt(this.publicKey, this.userData, tDlocUserData),
    });
  }

  public async bootstrap(): Promise<void> {
    const contactInfo = await this.encryptedCall(v1.GetDlocContactInfo)({});
    this.contactEmail = contactInfo.emailAddress;
    this.contactName = contactInfo.name;

    const { keys } = await this.encryptedCall(v1.GetSharedKeysForDloc)({});
    if (keys) {
      keys.forEach((key) => {
        this.versionedKeys[key.version] = {
          publicKey: key.publicKey,
          privateKey: Box.tsDecrypt(key.encryptedKey, this.publicKey, this.privateKey, tKey)
        };
      });
    }

    const { encryptedUserData } = await this.encryptedCall(v1.BootstrapUser)({});
    if (encryptedUserData) {
      this.userData = Box.tsDecrypt(encryptedUserData, this.publicKey, this.privateKey, tDlocUserData);
      if (!this.userData.signingKeys?.publicKey || !this.userData.signingKeys?.privateKey) {
        const signingKeys = crypto_sign_keypair();
        this.userData.signingKeys = signingKeys;
        await this.saveUserData();
        await this.encryptedCall(v1.SaveSigningPublicKey)({ publicKey: signingKeys.publicKey });
      }
    } else {
      this.userData = {};
    }
  }

  public async viewLoc(locId: string): Promise<void> {
    await this.submitEvent('view LOC', {
      loc: locId
    });
  }

  // Account Setup =============================================================
  public async checkPasswordStrength(password: string): Promise<void> {
    if (password.length < 8) {
      await this.submitEvent('password update failed', {
        error: 'password is too short'
      });
      throw new Error('password is too short');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const hash = sha1(password) as string;
    const prefix = hash.substr(0, 5);
    const { hashes } = await this.call(v1.CheckPasswordStrength)({ prefix });

    if (hashes.includes(hash)) {
      await this.submitEvent('password update failed', {
        error: 'password is weak or has been compromised'
      });
      throw new Error('found match');
    }
  }

  // Backup Codes ==============================================================
  public async createBackups(
    n: number,
    salt = commonBadgeSalt
  ): Promise<string[]> {
    const encryptedEnvelopes: { [index: string]: Uint8Array } = {};
    const codes: string[] = [];

    await this.submitEvent('generate new backup codes', {});

    for (let i = 0; i < n; i++) {
      const backup = OPAQUE.createBackup(
        this.username,
        {
          pk: this.publicKey,
          sk: this.privateKey,
        },
        salt
      );

      // TODO: change this index format?
      const index = to_hex(crypto_hash_sha256(backup.code));
      encryptedEnvelopes[index] = backup.encrypted;
      codes.push(backup.code);
    }

    await this.encryptedCall(v1.SaveBackupCodes)({ codes: encryptedEnvelopes });
    return codes;
  }

  public async emailBackupCodes(email: string, codes: string[]): Promise<void> {
    await this.encryptedCall(v1.MailBackupCodes)({
      email,
      codes,
    });
  }

  public async useBackupCode(code: string, salt = commonBadgeSalt): Promise<void> {
    // TODO: change this index format?
    const index = to_hex(crypto_hash_sha256(code));

    // TODO: error handling.
    const resp = await this.call(v1.UseBackupCode_Step1_Find)({ code: index });

    this.userID = resp.userID;
    this.serverKey = resp.serverPublicKey;

    // Stage all the information needed for finalizing the request.
    const decrypted = OPAQUE.decryptBackup(resp.encryptedEnvelope, code, salt);
    this.username = decrypted.username;
    this.publicKey = decrypted.keys.pk;
    this.privateKey = decrypted.keys.sk;
  }

  public async burnBackupCode(
    code: string,
    newPassword: string,
    badgeSalt = commonBadgeSalt
  ): Promise<unknown> {
    // TODO: change this index format?
    const codeIndex = to_hex(crypto_hash_sha256(code));

    const passwordHash = OPAQUE.makePassword(newPassword, badgeSalt);

    const alpha = OPAQUE.mask(passwordHash);
    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: alpha.point,
    });

    const unmasked = OPAQUE.unmask(beta, alpha.mask);
    const encrypted = OPAQUE.encrypt(unmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    return this.encryptedCall(v1.UseBackupCode_Step3_Finalize)({
      encryptedEnvelope: encrypted,
      codeIndex,
    });
  }

  // Account Management ========================================================
  /**
   * updatePassword creates a new envelope and badge for the user and saves
   * them.
   */
  public async updatePassword(oldPassword: string, newPassword: string, badgeSalt = commonBadgeSalt): Promise<unknown> {
    // Essentially perform a login to verify that the user has entered
    // their correct password to confirm their intent.
    const usernameHash = OPAQUE.makeUsername(this.username);
    const oldPasswordHash = OPAQUE.makePassword(oldPassword, badgeSalt);
    const oldAlpha = OPAQUE.mask(oldPasswordHash);

    const loginData = await this.call(v1.Login)({
      index: usernameHash,
      alpha: oldAlpha.point,
      passwordCheck: true
    });

    const oldUnmasked = OPAQUE.unmask(loginData.beta, oldAlpha.mask);
    OPAQUE.decrypt(oldUnmasked, loginData.encryptedEnvelope);

    // If the decryption succeeded, the user entered their correct password
    // and we should carry out the password change.

    const passwordHash = OPAQUE.makePassword(newPassword, badgeSalt);
    const newAlpha = OPAQUE.mask(passwordHash);

    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: newAlpha.point,
    });

    const newUnmasked = OPAQUE.unmask(beta, newAlpha.mask);
    const encrypted = OPAQUE.encrypt(newUnmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    await this.submitEvent('update password', {});
    return this.encryptedCall(v1.UpdatePassword)({
      newIndex: OPAQUE.makeUsername(this.username),
      newEnvelope: encrypted,
    });
  }

  public async updateContactInfo(name: string, email: string): Promise<unknown> {
    await this.submitEvent('update contact info', {});
    if (email !== this.contactEmail) {
      const currentContactName = this.contactName;
      try {
        this.contactName = name;
        await this.resetAccountRecovery(email, this.userData.phoneNumber);
      } catch (e) {
        this.contactName = currentContactName;
        throw e;
      }
    } else {
      return this.encryptedCall(v1.UpdateDlocContactInfo)({
        emailAddress: email,
        name,
      });
    }
  }

  public async getContactInfo(): Promise<{name: string; email: string}> {
    const c = await this.encryptedCall(v1.GetDlocContactInfo)({});

    return {
      name: c.name,
      email: c.emailAddress,
    };
  }

  // Entries ===================================================================
  public async getCases(): Promise<dlocEntry[]> {
    if (this.versionedKeys && Object.keys(this.versionedKeys).length > 0) {
      const { cases: rawEntries } = await this.encryptedCall(v1.GetCasesDloc)({});

      const entries = rawEntries.map((raw) => {
        const assignmentDataKeyVersion = raw.assignmentDataDlocKeyVersion;
        const assignmentDataKey = this.versionedKeys[assignmentDataKeyVersion];
        const data: assignmentData = Box.tsDecrypt(
          raw.encryptedAssignmentData,
          assignmentDataKey.publicKey,
          assignmentDataKey.privateKey,
          tAssignmentData
        );

        let questionnaire: closeOutQuestionnaire;
        if (raw.encryptedQuestionnaire) {
          const questionnaireKey = this.versionedKeys[raw.questionnaireDlocKeyVersion];
          questionnaire = Box.tsDecrypt(
            raw.encryptedQuestionnaire,
            questionnaireKey.publicKey,
            questionnaireKey.privateKey,
            tCloseOutQuestionnaire
          );
        }

        const notesKeyVersion = raw.notesDlocKeyVersion;
        const notesKey = this.versionedKeys[notesKeyVersion];
        const notes: string = raw.encryptedDlocNotes
          ? Box.tsDecrypt(
            raw.encryptedDlocNotes,
            notesKey.publicKey,
            notesKey.privateKey,
            tstring)
          : undefined;

        return ({
          data,
          questionnaire,
          notes,
          info: raw
        });
      });

      return entries.map(({ data, questionnaire, notes, info }) => {
        const st = info.status ? CaseStatus[info.status] : null;

        return {
          id: info.id,
          userID: data.userID,
          incidentState: data.incidentState,
          preferredLanguage: data.preferredLanguage,
          accommodationsNeeded: data.accommodationsNeeded,
          userSignupCampus: info.userSignupCampus,
          userCampusAtCreation: info.userCampusOnCreation,
          created: info.created,
          lastEdited: info.lastEdited,
          matchFound: info.matchFound,
          statusChanged: info.statusChanged,
          perpetratorId: info.perpId,
          assignedLOC: info.assignedLOC as LOC,
          status: st ?? CaseStatus.NO_CONTACT_ATTEMPTED,
          statusHistory: info.statusHistory,
          dateAssigned: info.assigned,
          closeOutQuestionnaire: questionnaire,
          assignmentHistory: info.locAssignmentHistory,
          notes,
          survivorHasManyEntries: info.survivorHasManyEntries
        };
      });
    }

    return [];
  }

  public async saveNotes(caseId: string, notes: string): Promise<void> {
    const currentKeyVersion = this.getLatestSharedKeyVersion();
    const currentKey = this.versionedKeys[currentKeyVersion];
    const encryptedNotes = Box.tsEncrypt(currentKey.publicKey, notes, tstring);

    await this.encryptedCall(v1.SaveDlocCaseNotes)({ caseId, encryptedNotes, dlocKeyVersion: currentKeyVersion });
  }


  public async assignCase(entryId: string, locId: string, survivorId: string): Promise<void> {
    const keys = await this.encryptedCall(v1.GetKeysForCaseAssignment)({
      entryId,
      locId,
      survivorId
    });

    const entryKeyDlocKey = this.versionedKeys[keys.dlocKeyVersionForEntryKey];
    const entryKeyVersionedKeyBox = Box.tsDecrypt(keys.entryKey, entryKeyDlocKey.publicKey, entryKeyDlocKey.privateKey, tVersionedKeyBox);
    const entryKeyLoc = Box.tsEncrypt(keys.locPublicKey, entryKeyVersionedKeyBox, tVersionedKeyBox);

    const contactInfoKeyDlocKey = this.versionedKeys[keys.dlocKeyVersionForContactInfoKey];
    const contactInfoKeyVersionedKeyBox =
      Box.tsDecrypt(keys.contactInfoKey, contactInfoKeyDlocKey.publicKey, contactInfoKeyDlocKey.privateKey, tVersionedKeyBox);
    const contactInfoKeyLoc = Box.tsEncrypt(keys.locPublicKey, contactInfoKeyVersionedKeyBox, tVersionedKeyBox);

    const { notes: unassignedNotes } = await this.encryptedCall(v1.GetLocNotesForCase)({
      caseId: entryId
    });

    const assignedNotes: identifiedEncryptedNotes[] = [];
    for (const encryptedNotes of unassignedNotes) {
      const dlocKey = this.versionedKeys[encryptedNotes.dlocKeyVersion];
      const decryptedNotes = Box.tsDecrypt(encryptedNotes.encrypted, dlocKey.publicKey, dlocKey.privateKey, tEncryptedBox);
      const reEncryptedNotes = Box.tsEncrypt(keys.locPublicKey, decryptedNotes, tEncryptedBox);
      assignedNotes.push({
        notesId: encryptedNotes.notesId,
        encrypted: reEncryptedNotes
      });
    }

    await this.submitEvent('assign case', {
      entry: entryId,
      loc: locId
    });

    await this.encryptedCall(v1.AssignCase)({
      locId,
      entryId,
      survivorId,
      entryKeyLoc,
      contactInfoKeyLoc,
      notes: assignedNotes
    });
  }

  public async clearAssignCase(entryId: string, locId: string, userId: string): Promise<void> {
    await this.submitEvent('deassign case', {
      entry: entryId,
      loc: locId
    });

    await this.encryptedCall(v1.UnassignCase)({
      entryId,
      locId,
      survivorId: userId
    });
  }

  public async getLOCs(): Promise<{
    id: string;
    name: string;
    email: string;
    practice: string;
    state: string;
    dateAdded: Date;
  }[]> {
    return this.encryptedCall(v1.GetLOCS)({});
  }

  public async getUnactivatedLocs(): Promise<{
    id: string;
    name: string;
    email: string;
    practice: string;
    state: string;
    dateAdded: Date;
  }[]> {
    return this.encryptedCall(v1.GetRegisteredUnactivatedLocs)({});
  }

  public async getUnregisteredLocs(): Promise<{
    id: string;
    name: string;
    email: string;
    practice: string;
    state: string;
    dateAdded: Date;
  }[]> {
    return this.encryptedCall(v1.GetUnregisteredLocs)({});
  }

  public async getDeactivatedLocs(): Promise<{
    id: string;
    name: string;
    email: string;
    practice: string;
    state: string;
    dateAdded: Date;
    dateDeactivated: Date;
  }[]> {
    return this.encryptedCall(v1.GetDeactivatedLocs)({});
  }

  public async inviteNewLoc(locData?: NewLocData, locId?: string): Promise<boolean> {
    return await this.encryptedCall(v1.SendLocInvitation)({
      locData,
      locId
    });
  }

  public async deactivateLoc(locId: string, locEmail: string, locPhone: string): Promise<void> {
    const recoveryClient = await this.getRecoveryClient();
    try {
      await recoveryClient.deleteByProxy(
        locEmail,
        locPhone,
        this.userData.signingKeys?.privateKey,
        this.userID,
        'l'
      );
    } catch (error) {
      try {
        await this.submitEvent('Delete LOC recovery data', { error: (error as Error).message });
      } catch {
        // Ignore this error
      }

      throw new Error("Error deleting the LOC's recovery data. Please try again later.");
    }

    let locDeactivatedSuccessfully = false;
    try {
      const { success } = await this.encryptedCall(v1.DlocDeactivateLoc)({ locId });
      locDeactivatedSuccessfully = success;
    } catch (e) {
      try {
        await this.submitEvent('Deactivate LOC', { error: (e as Error).message, locId });
      } catch {
        // Ignore this error
      }
      throw new Error('Error deactivating the LOC. Please contact tech support for assistance.');
    }

    if (!locDeactivatedSuccessfully) {
      throw new Error('LOC not deactivated. Please contact tech support for assistance.');
    }
  }

  public async getCurrentLocPlus(locId: string): Promise<string> {
    const { locPlusId } = await this.encryptedCall(v1.GetLocPlusForLoc)({ locId });
    return locPlusId;
  }

  public async validateSelectLocPlusToken(token: string): Promise<{ locId: string; locName: string }> {
    return await this.encryptedCall(v1.ValidateLocPlusSelectionToken)({ token });
  }

  public async sendLocActivationRequest(locId: string, locPlusId: string): Promise<void> {
    const { success } = await this.encryptedCall(v1.SendLocActivationRequest)({
      locId,
      locPlusId
    });

    if (!success) {
      throw new Error('Error selecting LOC+. Please try again');
    }
  }

  public async assignLocStar(locStarId: string): Promise<void> {
    await this.encryptedCall(v1.AssignLocStar)({ locId: locStarId });
  }

  public async updateStatus(entryId: string, status: CaseStatus): Promise<void> {
    const { success } = await this.encryptedCall(v1.DlocUpdateStatus)({
      entryId,
      status
    });

    if (!success) {
      throw new Error('Unknown error updating status');
    }
  }

  public async getAllDlocs(): Promise<dloc[]> {
    const { dlocs } = await this.encryptedCall(v1.GetAllDlocs)({});
    return dlocs;
  }

  // Account creation
  public async inviteNewDloc(dlocData?: NewDlocData, dlocId?: string): Promise<boolean> {
    const { success } = await this.encryptedCall(v1.SendDlocInvitation)({
      data: dlocData,
      id: dlocId
    });

    return success;
  }

  public async validateAccountToken(token: string): Promise<NewDlocData> {
    return await this.call(v1.ValidateDlocAccountToken)({ token });
  }

  public async registerAccount(
    username: string,
    password: string,
    token: string = this.token,
    badgeSalt: string = commonBadgeSalt
  ): Promise<void> {
    let encryptedKeys: Uint8Array;
    try {
      const lowercaseUsername = username.toLowerCase();
      const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
      const passwordHash = OPAQUE.makePassword(password, badgeSalt);
      const alpha = OPAQUE.mask(passwordHash);
      const keys = OPAQUE.generateKeys();

      const oprfData = await this.call(v1.CreateAccount_Step1_OPRF)({
        token,
        index: usernameHash,
        alpha: alpha.point,
        userPublicKey: keys.pk,
        privacyPolicyAccepted: null,
        campusName: null,
        emailDomain: null
      });

      const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
      encryptedKeys = OPAQUE.encrypt(unmasked, keys);

      this.username = lowercaseUsername;
      this.userID = oprfData.userID;
      this.serverKey = oprfData.serverPublicKey;
      this.publicKey = keys.pk;
      this.privateKey = keys.sk;
    } catch (error) {
      const event = {
        action: 'Register DLOC account',
        token,
        success: false,
        error: (error as Error).message,
        service_name: 'dloc'
      };

      try {
        await this.call(v1.HoneycombEvent)({ event });
      } catch {
        // Swallow it
      }
      throw error;
    }

    try {
      await this.encryptedCall(v1.CreateAccount_Step2_Finalize)({
        envelope: encryptedKeys
      });
    } catch (err) {
      try {
        await this.submitEvent('Create account step 2', { error: (err as Error).message });
      } catch {
        // fail silently
      }

      try {
        await this.encryptedCall(v1.UndoCreateAccountStep1)({});
      } catch (e) {
        try {
          await this.submitEvent('Roll back create account step 1', { error: (e as Error).message });
        } catch {
          // fail silently
        }
        throw new Error('Error creating account. Please ask an existing DLOC to restart the process.');
      } finally {
        await this.logout();
      }
      throw new Error ('Error creating account. Please try submitting again.');
    }

  }

  public async activateNewDloc(newDlocId: string): Promise<void> {
    const { dlocPublicKey } =
        await this.encryptedCall(v1.GetDlocPublicKey)({ dlocId: newDlocId });

    const encryptedVersionedKeys: {
      version: number;
      key: Uint8Array;
    }[] = [];
    Object.keys(this.versionedKeys).forEach((version) => {
      const { privateKey } = this.versionedKeys[version] as { publicKey: Uint8Array; privateKey: Uint8Array };
      const encryptedPrivateKey = Box.tsEncrypt(dlocPublicKey, privateKey, tKey);
      encryptedVersionedKeys.push({
        version: parseInt(version, 10),
        key: encryptedPrivateKey
      });
    });

    const { success } = await this.encryptedCall(v1.SaveVersionedKeysForNewDloc)({
      dlocId: newDlocId,
      keys: encryptedVersionedKeys
    });

    if (!success) {
      throw new Error('Error activating the new DLOC.');
    }
  }

  // Key management
  public async generateSharedKey(): Promise<boolean> {
    try {
      const { keys } = await this.encryptedCall(v1.GetDlocPublicKeys)({});
      const newKeyVersion = this.getLatestSharedKeyVersion() + 1;

      const sharedKeys = KeyGenerationHelper.generateSharedKeys();
      const encryptedSecretKeys = KeyGenerationHelper.encryptSharedKeys(keys, sharedKeys.privateKey);
      const { success } = await this.encryptedCall(v1.SaveVersionedSharedDlocKeys)({
        version: newKeyVersion,
        sharedPublicKey: sharedKeys.publicKey,
        encryptedSecretKeys: encryptedSecretKeys.map(({ userId, encryptedKey }) => ({
          dlocId: userId,
          encryptedKey
        }))
      });

      this.versionedKeys[newKeyVersion] = {
        publicKey: sharedKeys.publicKey,
        privateKey: sharedKeys.privateKey
      };

      return success;
    } catch (error) {
      const event = {
        action: 'Generate New Version of Shared DLOC Keys',
        success: false,
        error: (error as Error).message,
        service_name: 'dloc'
      };
      try {
        await this.call(v1.HoneycombEvent)({ event });
      } catch {
        // Swallow it
      }

      throw new Error(`Key generation failed: ${(error as Error).message}`);
    }
  }

  public async reencryptDataWithLatestKey(): Promise<boolean> {
    const keyVersion = this.getLatestSharedKeyVersion();
    const key = this.versionedKeys[keyVersion];
    try {
      const data = await this.encryptedCall(v1.GetDataForDlocReencryption)({});
      const reencryptedEntryData = this.reencryptEntryData(key.publicKey, data.entryData);
      const reencryptedContactInfoKeys = this.reencryptContactInfoKeys(key.publicKey, data.contactInfoKeys);
      const { success } = await this.encryptedCall(v1.SaveDataReencryptedByDloc)({
        dlocKeyVersion: keyVersion,
        entryData: reencryptedEntryData,
        contactInfoKeys: reencryptedContactInfoKeys
      });
      return success;
    } catch (error) {
      const event = {
        action: 'Reencrypt data with new version of shared DLOC key',
        success: false,
        error: (error as Error).message,
        service_name: 'dloc'
      };
      try {
        await this.call(v1.HoneycombEvent)({ event });
      } catch {
        // Swallow it
      }

      throw new Error(`Error re-encrypting data: ${(error as Error).message}`);
    }
  }

  public async assignKeysForShareRegeneration(locId: string): Promise<boolean> {
    const { locKeys } = await this.encryptedCall(v1.GetLOCKeys)({});
    const locKey = locKeys[locId];
    const { entryKeys } = await this.encryptedCall(v1.DlocGetKeysForEntriesForShareRecalculation)({ locId });

    const keysForSaving: { entryId: string; encryptedKey: Uint8Array }[] = [];
    entryKeys.forEach((key) => {
      const partiallyDecryptedKey = Box.rawDecrypt(
        key.encryptedKey,
        this.versionedKeys[key.dlocKeyVersion].publicKey,
        this.versionedKeys[key.dlocKeyVersion].privateKey);
      const reEncryptedKey = Box.rawEncrypt(locKey, partiallyDecryptedKey);
      keysForSaving.push({
        entryId: key.entryId,
        encryptedKey: reEncryptedKey
      });
    });

    const { success } = await this.encryptedCall(v1.DlocSaveKeysForShareRecalculation)({
      locId,
      keys: keysForSaving
    });

    return success;
  }

  // Account Recovery
  public async getRecoveryClient(): Promise<RecoveryClient> {
    if(this.recoveryClient !== undefined) {
      return this.recoveryClient;
    }

    const env = await this.getEnvironmentVariables();
    this.recoveryClient = new RecoveryClient(
      env.UI_ENV_RECOVERY_SERVER_1_URL,
      env.UI_ENV_RECOVERY_SERVER_2_URL,
    );

    return this.recoveryClient;
  }

  public async verifyAccountRecoveryToken(token: string): Promise<{
    success: boolean;
    questions: string[];
  }> {
    const client = await this.getRecoveryClient();
    const questions = await client.recovery_step1_get_questions(token);

    return Promise.resolve({
      success: true,
      questions
    });
  }

  public async validateSecurityQuestionAnswers(
    answers: string[],
    token: string
  ): Promise<boolean> {
    try {
      const client = await this.getRecoveryClient();
      const envelope = await client.recovery_step2_complete(token, answers);

      const { serverKey } = await this.call(v1.GetServerPublicKey)({});
      this.userID = envelope.userID;
      this.username = envelope.username;
      this.publicKey = envelope.envelope.pk;
      this.privateKey = envelope.envelope.sk;
      this.serverKey = serverKey;
      return true;
    } catch (e) {
      try {
        const event = {
          action: 'Validate security question answers',
          error: (e as Error).message,
          service_name: 'dloc'
        };
        await this.call(v1.HoneycombEvent)({ event });
      } catch (error) {
        // Swallow this error
      }
      return false;
    }
  }

  public async submitLocAccountRecoveryRequest(email: string, phoneNumber: string): Promise<{ success: boolean }> {
    try {
      const client = await this.getRecoveryClient();

      await client.request(email, phoneNumber, 'MAIL_LOC_CANONICAL_DOMAIN', 'l');
    } catch (error) {
      try {
        await this.submitEvent('DLOC submit LOC recovery request', { error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      return { success: false };
    }

    return { success: true };
  }

  public async setUpAccountRecovery(
    email: string,
    phoneNumber: string,
    securityQuestions: string[],
    answers: string[]
  ): Promise<{ success: boolean }> {
    const { isActive } = await this.encryptedCall(v1.GetCurrentUserActiveStatus)({});
    if (!isActive) {
      throw new Error('Cannot set up account recovery data; account is not active');
    }

    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }

    if (!this.userData) {
      this.userData = {};
    }

    let marker: Uint8Array;
    if (this.userData.marker) {
      marker = this.userData.marker;
    } else {
      marker = randombytes_buf(64);
      this.userData.marker = marker;
    }

    // Create an ownership key for this entry so that we can delete it later
    // if we need to.
    const keys = Matching.makeOwnershipKey();
    this.userData.recoveryOwnershipKey = keys.privateKey;
    await this.saveUserData();

    const recoveryClient = await this.getRecoveryClient();
    await recoveryClient.setup(
      email,
      phoneNumber,
      questions,
      this.userID,
      this.username,
      marker,
      {
        pk: this.publicKey,
        sk: this.privateKey
      },
      keys.publicKey,
      'd'
    );
    await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    return { success: true };
  }

  public async updateAccountRecoveryData(
    email: string,
    phone: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<void> {
    try {
      if (email !== this.contactEmail) {
        if (!this.serverKey) {
          const { serverKey } = await this.call(v1.GetServerPublicKey)({});
          this.serverKey = serverKey;
        }

        await this.encryptedCall(v1.UpdateDlocContactInfo)({
          emailAddress: email,
          name: this.contactName
        });

        this.contactEmail = email;

        try {
          await this.submitEvent('Update DLOC email', { success: true });
        } catch {
          // Swallow this error
        }
      }

      this.userData.securityQuestions = securityQuestions;
      this.userData.securityAnswers = answers;
      this.userData.phoneNumber = phone;
      await this.saveUserData();
    } catch (error) {
      try {
        await this.submitEvent('DLOC update account recovery data', { success: false, error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      throw new Error('There was an error updating your account information; please try again');
    }
  }

  public async resetAccountRecovery(
    email: string,
    phoneNumber: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<{ success: boolean }> {
    const { isActive } = await this.encryptedCall(v1.GetCurrentUserActiveStatus)({});
    if (!isActive) {
      throw new Error('Cannot update account recovery data; account is not active');
    }

    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }
    const client = await this.getRecoveryClient();
    try {
      await client.update(
        this.contactEmail,
        this.userData.phoneNumber,
        email,
        phoneNumber,
        questions,
        this.userID,
        this.username,
        this.userData.marker,
        {
          pk: this.publicKey,
          sk: this.privateKey
        },
        this.userData.recoveryOwnershipKey,
        'd'
      );
      await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    } catch (e) {
      try {
        await this.submitEvent('DLOC reset account recovery', { error: (e as Error).message });
      } catch {
        // swallow this error
      }
      throw new Error('There was an error updating your data. Please try again.');
    }
    return { success: true };
  }

  public async submitEvent(action: string, data: Record<string, unknown>) {
    const event = {
      action,
      ...data,
      service_name: 'dloc',
      name: action
    };
    await this.call(v1.HoneycombEvent)({ event });
  }

  private reencryptEntryData(dlocPublicKey: Uint8Array, entryData: EntryData[]) {
    const reencryptedData: ReencryptedEntryData[] = [];
    entryData.forEach((data) => {
      const reencryptedAssignmentData = this.reencryptData(dlocPublicKey, tAssignmentData, {
        dlocKeyVersion: data.assignmentData.dlocKeyVersion,
        encrypted: data.assignmentData.encryptedAssignmentData
      });

      const reencryptedQuestionnaire = data.questionnaire ? this.reencryptData(dlocPublicKey, tCloseOutQuestionnaire, {
        dlocKeyVersion: data.questionnaire.dlocKeyVersion,
        encrypted: data.questionnaire.encryptedQuestionnaire
      }) : null;

      const dlocNotes = data.dlocNotes ? {
        dlocKeyVersion: data.dlocNotes.dlocKeyVersion,
        encrypted: data.dlocNotes.encryptedDlocNotes
      } : null;
      const reencryptedDlocNotes = this.reencryptData(dlocPublicKey, tstring, dlocNotes);

      const entryKey = data.entryKey ? {
        dlocKeyVersion: data.entryKey.dlocKeyVersion,
        encrypted: data.entryKey.encryptedKey
      } : null;
      const reencryptedEntryKey = this.reencryptData(dlocPublicKey, tVersionedKeyBox, entryKey);

      const reencryptedLocNotes = data.locNotes.map((notes) => ({
        id: notes.id,
        encryptedLocNotes: this.reencryptData(dlocPublicKey, tEncryptedBox, {
          dlocKeyVersion: notes.dlocKeyVersion,
          encrypted: notes.encryptedLocNotes
        })})
      );

      reencryptedData.push({
        entryId: data.entryId,
        encryptedAssignmentData: reencryptedAssignmentData,
        encryptedQuestionnaire: reencryptedQuestionnaire,
        encryptedDlocNotes: reencryptedDlocNotes,
        encryptedKey: reencryptedEntryKey,
        locNotes: reencryptedLocNotes
      });
    });

    return reencryptedData;
  }

  private reencryptContactInfoKeys(dlocPublicKey: Uint8Array, contactInfoKeys: {
    survivorId: string;
    dlocKeyVersion: number;
    encryptedKey: Uint8Array;
  }[]) {
    return contactInfoKeys.map((key) => ({
      survivorId: key.survivorId,
      encryptedKey: this.reencryptData(dlocPublicKey, tVersionedKeyBox, {
        dlocKeyVersion: key.dlocKeyVersion,
        encrypted: key.encryptedKey
      })
    }));
  }

  private reencryptData<T>(dlocPublicKey: Uint8Array, dataType: T, data: {
    dlocKeyVersion: number;
    encrypted: Uint8Array;
  }) {
    if (data === null) {
      return null;
    }

    let key: {publicKey: Uint8Array; privateKey: Uint8Array };
    if (data.dlocKeyVersion) {
      key = this.versionedKeys[data.dlocKeyVersion];
    } else {
      key = {
        publicKey: this.publicKey,
        privateKey: this.privateKey
      };
    }
    const decryptedData = Box.tsDecrypt(
      data.encrypted,
      key.publicKey,
      key.privateKey,
      dataType as Decoder<string | Uint8Array, unknown>
    );

    return Box.tsEncrypt(
      dlocPublicKey,
      decryptedData,
      dataType as Encoder<unknown, string | Uint8Array>
    );
  }

  private async doLogin(username: string, index: string, alpha: OPRF.Alpha) {
    const oprfData = await this.call(v1.Login)({
      index,
      alpha: alpha.point,
      passwordCheck: false
    });

    const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
    const decrypted = OPAQUE.decrypt(unmasked, oprfData.encryptedEnvelope);

    this.username = username;
    this.userID = oprfData.userID;
    this.serverKey = oprfData.serverPublicKey;
    this.publicKey = decrypted.pk;
    this.privateKey = decrypted.sk;
  }

  private getLatestSharedKeyVersion = () => {
    if (Object.keys(this.versionedKeys).length > 0) {
      const keyVersions = Object.keys(this.versionedKeys);
      const maxSharedKeyVersion = keyVersions
        .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))[0];
      return  parseInt(maxSharedKeyVersion, 10);
    } else {
      return 0;
    }
  };
}
