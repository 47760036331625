/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import {
  useEffect,
  useState
} from 'preact/hooks';
import * as emailValidator from 'email-validator';
import { NewDlocData } from '../../../../client';
import { Modal } from '../../../../../../lib/components/modal';

import './styles.scss';

export const DlocInviteModal = ({
  onClose,
  onInvite
}: {
  onClose: () => void;
  onInvite: (data: NewDlocData) => void;
}): JSX.Element => {
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [formFilled, setFormFilled] = useState<boolean>(false);
  const [formState, setFormState] = useState<NewDlocData>({
    name: '',
    email: ''
  });

  useEffect(() => {
    setFormFilled(formState.name !== '' && formState.email !== '');
    setValidEmail(emailValidator.validate(formState.email));
  }, [formState]);

  return (
    // eslint-disable-next-line @typescript-eslint/require-await
    <Modal closeFunc={async () => onClose()} canExit={true}>
      <div id='dlocInviteModal'>
        <h3>Add a new DLOC</h3>
        <div>
          <label htmlFor="nameInput">DLOC Name</label>
          <input id="nameInput" type="text" onChange={(e) => {
            setFormState({
              ...formState,
              name: e.currentTarget.value.trim()
            });
          }} />

          <label htmlFor="emailInput">DLOC Email</label>
          <input id="emailInput" type="text" onChange={(e) => {
            setFormState({
              ...formState,
              email: e.currentTarget.value.trim()
            });
          }} />
        </div>
      </div>
      { !validEmail && formState.email !== '' && (
        <div className='pwerror'>Email address is invalid</div>
      )}
      <div id="buttonDiv" className="topPadding">
        <button className="secondaryButton" onClick={onClose}>Cancel</button>
        <button
          disabled={!(formFilled && validEmail)}
          className={formFilled && validEmail ? 'primaryButton' : 'secondaryButton'}
          onClick={() => onInvite(formState)}
        >Send Invitation</button>
      </div>
    </Modal>
  );
};
