/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import { Modal } from './modal';
import { useState } from 'preact/hooks';
import PhoneNumberInput from './PhoneNumberInput';

export const SubmitAccountRecoveryRequest = (
  {
    onCancel,
    onSubmit,
    name,
    emailAddress
  }: {
    onCancel: () => void;
    onSubmit: (email: string, phone: string) => void;
    name: string;
    emailAddress: string;
  }
): h.JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const submitRequest = () => {
    onSubmit(emailAddress, phoneNumber);
  };

  return (
    <Modal closeFunc={async () => {
      onCancel();
      return Promise.resolve();
    }}
    canExit={true}>
      <div id='submitRecoveryRequestModal'>
        <h2>Enter recovery phone number for {name}</h2>

        <p>
          Note: The phone number will be formatted as a US number on the screen. International numbers will be saved
          properly even if they are not displayed as desired as you input them.
        </p>

        <PhoneNumberInput value={phoneNumber} onChange={(phone: string) => {
          const unformattedPhone = phone.replace(/[^0-9]/g, '');
          setPhoneNumber(`+${unformattedPhone}`);
        }} />

        <div style={{ textAlign: 'center' }}>
          <button className="secondaryButton" style={{ margin: '15px' }} onClick={onCancel}>Cancel</button>
          <button className="primaryButton" style={{ margin: '15px' }} onClick={submitRequest}>Submit Request</button>
        </div>
      </div>
    </Modal>
  );
};
