/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import {
  useRef,
  useState
} from 'preact/hooks';
import {
  useCases,
  useLocs
} from '../../dlocData';

import './styles.scss';
import { CaseStatus } from '../../../../../../lib/components/case-status';
import { ViewEditNotes } from '../../components/ViewEditNotes';
import { AssignClearModal } from '../../components/assign-clear-modal';
import {
  CaseStatus as DataCaseStatus,
  formatDate,
  MANY_ENTRIES
} from '../../../../../../lib/data';
import { SelectLocModal } from '../../components/select-loc-modal';

export const ViewCase = ({ id }: { id?: string; path?: string }): JSX.Element => {
  const { assignCase, cases, clearAssignCase } = useCases();
  const { locs } = useLocs();
  const [showEditNotes, setShowEditNotes] = useState<boolean>(false);
  const [showClearAssign, setShowClearAssign] = useState<boolean>(false);
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [renderCount, setRenderCount] = useState<number>(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const entry = cases.find((e) => e.id === id);

  if (!entry) {
    return null;
  }

  const assignmentHistory = entry.assignmentHistory.sort((a, b) => a.dateAssigned.getTime() - b.dateAssigned.getTime());
  const statusHistory = entry.statusHistory.sort((a, b) => a.dateUpdated.getTime() - b.dateUpdated.getTime());

  const handleClearAssign = () => {
    // Check for other cases assigned to the same LOC for the same survivor.
    // (If there are any then we don't want to delete the contact info key
    // for this LOC/Survivor combination.)
    const relevantCases = cases.filter((current) =>
      current.id !== entry.id &&
      current.assignedLOC?.id === entry.assignedLOC.id &&
      current.userID === entry.userID
    );

    if (relevantCases.length === 0) {
      clearAssignCase(entry, entry.assignedLOC.id, entry.userID);
    } else {
      clearAssignCase(entry, entry.assignedLOC.id, null);
    }

    setShowClearAssign(false);
    setRenderCount(renderCount + 1);
  };

  const handleAssign = (locID: string) => {
    const modalLoader = document.getElementById('modalLoader');
    modalLoader.style.display = 'flex';

    assignCase(entry, locID);

    modalLoader.style.display = 'none';
    setShowAssign(false);
    setRenderCount(renderCount + 1);
  };

  return (
    <Fragment>
      <main id="viewCase">
        <div id="caseDetails">
          <div id="caseBody">
            <a href="/dashboard" id="backEnchor">
              <p id="backLink">
                <img src={require('../../../assets/icons/chev-left.svg') as string} alt="left arrow" />
                Back to dashboard
              </p>
            </a>
            <header>
              <div>
                <h2>Case ID: { entry.id }</h2>
                <p>Survivor ID: {entry.userID}</p>
                <p>Perpetrator ID: { entry.perpetratorId }</p>
                <p>State of Incident: { entry.incidentState }</p>
                <p>Campus: { entry.userCampusAtCreation }</p>
              </div>
              <div>
                <h2>Matched Date: {formatDate(entry.matchFound)}</h2>
                <p>Created Date: {formatDate(entry.created)}</p>
                <CaseStatus status={entry.status} lastUpdated={entry.statusChanged} />
              </div>
            </header>
            {entry.survivorHasManyEntries && (
              <div><h2 className="highlighted centered">Survivor has at least {MANY_ENTRIES} entries in the system</h2></div>
            )}
            <div className="detail">
              <h2>Assignment Information</h2>
              <p>
                <b>Preferred Language</b>
              </p>
              <p>{entry.preferredLanguage ?? 'Not specified'}</p>
              <div className={entry.accommodationsNeeded ? 'highlighted' : ''}>
                <p>
                  <b>Accommodations Needed</b>
                </p>
                {entry.accommodationsNeeded && (
                  <p
                    dangerouslySetInnerHTML={{ __html: entry.accommodationsNeeded.replace(/\n/g, '<br />')}}
                  />
                )}
                {!entry.accommodationsNeeded && (
                  <p>Not specified</p>
                )}
              </div>
              <p>
                <b>Currently assigned to</b>
              </p>
              <p>{entry.assignedLOC && (
                <Fragment>
                  {entry.assignedLOC.name}<br />
                  <button className="iconButton" onClick={() => { setShowClearAssign(true); }}>Unassign case</button>
                </Fragment>
              )}
              {!entry.assignedLOC && (
                <Fragment>
                    Not currently assigned<br />
                  <button className="iconButton" onClick={() => { setShowAssign(true); }}>Assign case</button>
                </Fragment>
              )}
              </p>
              <p>
                <b>Date assigned</b>
              </p>
              <p>{entry.assignedLOC ? formatDate(entry.dateAssigned) : 'N/A'}</p>

              <h3>Assignment History</h3>
              <div id='assignmentHistory'>
                <table>
                  <tr>
                    <th>Name</th>
                    <th>Date Assigned</th>
                    <th>Date Unassigned</th>
                  </tr>
                  {assignmentHistory.map((assignment) => (
                    <tr key={assignment.dateAssigned.toISOString()}>
                      <td>{assignment.locName}</td>
                      <td>{assignment.dateAssigned.toLocaleString()}</td>
                      <td>{assignment.dateUnassigned ? assignment.dateUnassigned.toLocaleString() : 'N/A'}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
            <div className="detail">
              <div id='statusHistory'>
                <h2>Status</h2>
                <h3>Current Status</h3>
                <CaseStatus status={entry.status} lastUpdated={entry.statusChanged} /><br/>
                <h3>Status History</h3>
                <div id='statusHistory'>
                  {statusHistory.map((status) => (
                    <Fragment key={status.dateUpdated.toISOString()}>
                      <CaseStatus status={status.status as DataCaseStatus} lastUpdated={status.dateUpdated} /><br/>
                    </Fragment>))}
                </div>
              </div>
            </div>

            <div className="detail">
              <h2>Notes</h2>
              <button className="iconButton" onClick={() => setShowEditNotes(true)}>
                <img
                  src={require('../../../assets/icons/edit.svg') as string}
                  alt="edit notes"
                /> Edit notes
              </button>
              <br />
              <br />
              <textarea
                ref={textareaRef}
                value={entry.notes}
                disabled={true}
                style={{
                  width: '100%',
                  minHeight: textareaRef?.current ? `${textareaRef.current.scrollHeight}px` : '400px',
                  fontSize: '12pt',
                  color: '#225a75'
                }}
              />
            </div>
          </div>
        </div>
      </main>

      {showClearAssign && (
        <AssignClearModal
          onClose={() => setShowClearAssign(false)}
          onAssign={() => {
            handleClearAssign();
          }}
        />
      )}

      {showAssign && (
        <SelectLocModal
          onClose={() => { setShowAssign(false); }}
          onSelect={(caseId) => {
            handleAssign(caseId);
          }}
          title='Assign to Legal Options Counselor'
          locs={locs}
        />
      )}

      { showEditNotes && (
        <ViewEditNotes
          onClose={() => { setShowEditNotes(false); }}
          onSave={(notesText: string) => { entry.notes = notesText; }}
          entry={entry}
        />
      )}
    </Fragment>
  );
};
