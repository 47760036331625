/* eslint-disable react/react-in-jsx-scope */

import { useContext, useEffect, useState } from 'preact/hooks';
import { DLOCClient } from '../../../../client';
import { AuthClientContext } from '../../../../../../lib/init';
import { route } from 'preact-router';
import { Fragment, h } from 'preact';
import { useLocs } from '../../dlocData';
import './styles.scss';
import { Toast } from '../../../../../../lib/components/toast';
import { LocPlusSelected } from '../../components/loc-plus-selected';

export const SelectLocPlus = ({ client }: { client: DLOCClient; path: string }): JSX.Element => {
  const [ inErrorState, setInErrorState ] = useState<boolean>(false);
  const [ errorMsg, setErrorMsg ] = useState<string>('');
  const [ newLocId, setNewLocId ] = useState<string>('');
  const [ newLocName, setNewLocName ] = useState<string>('');
  const [ locPlusId, setLocPlusId ] = useState<string>('');
  const [ working, setWorking ] = useState<boolean>(false);
  const [ activationRequestSent, setActivationRequestSent ] = useState<boolean>(false);
  const [ tokenValidationFailed, setTokenValidationFailed ] = useState<boolean>(false);

  const { locs } = useLocs();

  const authClient = useContext(AuthClientContext);

  useEffect(() => {
    if (!authClient.loggedIn) {
      route('/');
      return;
    }

    const validate = async () => {
      try {
        const { locId, locName } = await client.validateSelectLocPlusToken(client.token);
        setNewLocId(locId);
        setNewLocName(locName);
      } catch (error) {
        setErrorMsg((error as Error).message);
        setInErrorState(true);
        setTokenValidationFailed(true);
      }
    };
    void validate();
  }, []);

  const sendLocActivationRequest = async () => {
    setWorking(true);
    try {
      await client.sendLocActivationRequest(newLocId, locPlusId);
      setActivationRequestSent(true);
    } catch (error) {
      setErrorMsg((error as Error).message);
      setInErrorState(true);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Fragment>
      <main id="selectLocPlusPage">
        <section className="formWrapper">
          { tokenValidationFailed && (
            <div className="error">
              Token validation failed!
            </div>
          )}
          { !activationRequestSent && (
            <Fragment>
              <div id="titleWrapper">
                <h1>Select an LOC+ for {newLocName}</h1>
              </div>
              <div id="textWrapper">
                <p>{newLocName} has created their credentials as a new Legal Options Counselor.
                Please select an LOC+ to complete the activation process for {newLocName}.</p>
              </div>

              <div className="inputWrapper">
                <label htmlFor='locPlusInput'>Select an LOC+</label>
                <select
                  className={locPlusId === '' ? 'gray' : null}
                  onChange={(evt) => {
                    setLocPlusId(evt.currentTarget.value);
                  }}
                  disabled={tokenValidationFailed}
                >
                  <option disabled selected value=''>
                    Select a Legal Options Counselor
                  </option>
                  {locs.map((loc) =>
                    <option key={loc.id} value={loc.id}>{loc.name}</option>)}
                </select>
              </div>

              <div id="next5">
                <button
                  className="btn primary"
                  onClick={sendLocActivationRequest}
                  disabled={!locPlusId || locPlusId === '' || tokenValidationFailed}
                >
                  Send LOC Activation Request
                </button>
              </div>
            </Fragment>
          )}

          { activationRequestSent && (
            <LocPlusSelected locName={newLocName} />
          )}
        </section>
      </main>

      { working && (
        <div id="loaderWrapper">
          <div className="loaderContainer">
            <div className="loader" />
          </div>
        </div>
      )}

      { inErrorState && (
        <Toast
          error={true}
          message={errorMsg}
          closeFunc={async () => Promise.resolve()}
        />
      )}
    </Fragment>
  );
};
