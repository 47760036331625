/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { DlocClientContext } from '../../components/DlocClientContext';
import {
  useContext,
  useEffect,
  useState
} from 'preact/hooks';
import { useLocs } from '../../dlocData';
import {
  formatDate,
  loc
} from '../../../../../../lib/data';
import { SubmitAccountRecoveryRequest } from '../../../../../../lib/components/SubmitAccountRecoveryRequest';
import { Toast } from '../../../../../../lib/components/toast';

import './styles.scss';
import {
  ConfirmDeactivation,
  UserForDeactivation
} from '../../../../../../lib/components/ConfirmDeactivation';

// eslint-disable-next-line no-empty-pattern
export const LocAdmin = ({}: { path?: string }): h.JSX.Element => {
  const client = useContext(DlocClientContext);
  const {
    locs,
    unregisteredLocs,
    unactivatedLocs,
    deactivatedLocs,
    refreshLocs,
    refreshDeactivatedLocs
  } = useLocs();
  const [sortedLocs, setSortedLocs] = useState<loc[]>([]);
  const [locToRecoveryAccountFor, setLocToRecoverAccountFor] = useState<loc | undefined>();
  const [locToDeactivate, setLocToDeactivate] = useState<loc | undefined>();
  const [userToDeactivate, setUserToDeactivate] = useState<UserForDeactivation | undefined>();
  const [showAccountRecoveryError, setShowAccountRecoveryError] = useState<boolean>(false);
  const [showAccountRecoverySuccess, setShowAccountRecoverySuccess] = useState<boolean>(false);
  const [accountDeactivationError, setAccountDeactivationError] = useState<string>('');
  const [showAccountDeactivationSuccess, setShowAccountDeactivationSuccess] = useState<boolean>(false);

  useEffect(() => {
    setSortedLocs(locs.sort((locA, locB) => {
      const a = locA.name;
      const b = locB.name;
      if ((!a && b) || (a < b)) {
        return -1;
      }

      if ((a && !b) || (a > b)) {
        return 1;
      }

      return 0;
    }));
  }, [locs]);

  const submitRecoveryRequest = async (email: string, phone: string) => {
    let loader: HTMLElement;
    try {
      loader = document.getElementById('loaderWrapper');
      loader.style.display = 'flex';
      setLocToRecoverAccountFor(undefined);
      const { success } = await client.submitLocAccountRecoveryRequest(email, phone);
      setShowAccountRecoverySuccess(success);
      setShowAccountRecoveryError(!success);
    } finally {
      if (loader) {
        loader.style.display = 'none';
      }
    }
  };

  const deactivateUser = async (email: string, phone: string) => {
    let loader: HTMLElement;
    try {
      loader = document.getElementById('loaderWrapper');
      loader.style.display = 'flex';
      const locId = userToDeactivate.id;
      setUserToDeactivate(undefined);
      await client.deactivateLoc(locId, email, phone);
      setShowAccountDeactivationSuccess(true);
    } catch (e) {
      setAccountDeactivationError((e as Error).message);
    } finally {
      if (loader) {
        loader.style.display = 'none';
      }
      refreshLocs();
      refreshDeactivatedLocs();
    }
  };

  return (
    <Fragment>
      <div id="loaderWrapper">
        <div className="loaderContainer">
          <div className='loader' />
        </div>
      </div>

      <main id="locAdminMain">
        <div id="locAdminCard">
          <h1>LOC Administration</h1>

          <h2>Active LOCs</h2>
          <div className="locTable">
            <table>
              <tbody>
                <tr className="locTableHeader">
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Action</th>
                </tr>
                {
                  sortedLocs.map((thisLoc) => (
                    <tr className="record" key={thisLoc.id}>
                      <td>{thisLoc.id}</td>
                      <td>{thisLoc.name}</td>
                      <td>{thisLoc.email}</td>
                      <td className="moreIcon">
                        <img src={require('../../assets/more.svg') as string} alt='more icon' />
                        <div className='moreDropdown'>
                          <button onClick={() => {
                            setLocToRecoverAccountFor(thisLoc);
                          }}>Recover Account</button>
                          <button onClick={() => {
                            setLocToDeactivate(thisLoc);
                          }}
                          disabled={sortedLocs.length < 2}>Deactivate Account</button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {deactivatedLocs && deactivatedLocs.length > 0 && (
            <Fragment>
              <hr />
              <h2>Deactivated LOCs</h2>
              <div className="locTable">
                <table>
                  <tbody>
                    <tr className="locTableHeader">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Date Deactivated</th>
                    </tr>
                    {
                      deactivatedLocs.map((thisLoc) => (
                        <tr className="record" key={thisLoc.id}>
                          <td>{thisLoc.id}</td>
                          <td>{thisLoc.name}</td>
                          <td>{thisLoc.email}</td>
                          <td>{formatDate(thisLoc.dateDeactivated)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}

          {unactivatedLocs && unactivatedLocs.length > 0 && (
            <Fragment>
              <hr />
              <h2>Unactivated LOCs</h2>
              <p style={{ textAlign: 'center' }}>(LOCs that have created credentials but have not been activated by an LOC*)</p>
              <div className="locTable">
                <table>
                  <tbody>
                    <tr className="locTableHeader">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Action</th>
                    </tr>
                    {
                      unactivatedLocs.map((thisLoc) => (
                        <tr className="record" key={thisLoc.id}>
                          <td>{thisLoc.id}</td>
                          <td>{thisLoc.name}</td>
                          <td>{thisLoc.email}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}

          {unregisteredLocs && unregisteredLocs.length > 0 && (
            <Fragment>
              <hr />
              <h2>Unregistered LOCs</h2>
              <p style={{ textAlign: 'center' }}>(LOCs that have been invited but have not yet created credentials)</p>
              <div className="locTable">
                <table>
                  <tbody>
                    <tr className="locTableHeader">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Action</th>
                    </tr>
                    {
                      unregisteredLocs.map((thisLoc) => (
                        <tr className="record" key={thisLoc.id}>
                          <td>{thisLoc.id}</td>
                          <td>{thisLoc.name}</td>
                          <td>{thisLoc.email}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </div>

        {locToRecoveryAccountFor && (
          <SubmitAccountRecoveryRequest
            onCancel={() => {
              setLocToRecoverAccountFor(undefined);
            }}
            onSubmit={submitRecoveryRequest}
            name={locToRecoveryAccountFor.name}
            emailAddress={locToRecoveryAccountFor.email}
          />
        )}

        {showAccountRecoveryError && (
          <Toast
            error={true}
            message="There was an error attempting to send account recovery email. Please try again."
            closeFunc={async () => {
              setShowAccountRecoveryError(false);
              return Promise.resolve();
            }}
          />
        )}

        {showAccountRecoverySuccess && (
          <Toast
            error={false}
            message="Account recovery email sent"
            closeFunc={async () => {
              setShowAccountRecoverySuccess(false);
              return Promise.resolve();
            }}
          />
        )}

        {locToDeactivate && (
          <ConfirmDeactivation
            onCancel={() => {
              setLocToDeactivate(undefined);
            }}
            onConfirm={(user: UserForDeactivation) => {
              setUserToDeactivate(user);
              setLocToDeactivate(undefined);
            }}
            user={{
              id: locToDeactivate.id,
              name: locToDeactivate.name,
              emailAddress: locToDeactivate.email
            }}
          />
        )}

        {userToDeactivate && (
          <SubmitAccountRecoveryRequest
            onCancel={() => {
              setUserToDeactivate(undefined);
            }}
            onSubmit={deactivateUser}
            name={userToDeactivate.name}
            emailAddress={userToDeactivate.emailAddress}
          />
        )}

        {accountDeactivationError && (
          <Toast
            error={true}
            message={accountDeactivationError}
            closeFunc={async () => {
              setAccountDeactivationError('');
              return Promise.resolve();
            }}
          />
        )}

        {showAccountDeactivationSuccess && (
          <Toast
            error={false}
            message="Account deactivated"
            closeFunc={async () => {
              setShowAccountDeactivationSuccess(false);
              return Promise.resolve();
            }}
          />
        )}
      </main>
    </Fragment>
  );
};
