/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import { Modal } from '../../../../../../lib/components/modal';

export const AssignClearModal = ({
  onClose,
  onAssign,
}: {
  onClose: () => void;
  onAssign: () => void;
}): JSX.Element => (
  // eslint-disable-next-line @typescript-eslint/require-await
  <Modal closeFunc={async () => onClose()} canExit={true}>
    <div id='assignModal'>
      <h3>Reassign Legal Options Counselor</h3>
    </div>
    <div id='codesButtons' className='hideOnPrint topPadding'>
      <button id='completeButton' className='secondaryButton' onClick={onClose}>
        Cancel
      </button>
      <button className='primaryButton' onClick={() => onAssign()}>
        Save changes
      </button>
    </div>
  </Modal>
);
