/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import {
  useEffect,
  useState
} from 'preact/hooks';
import { DLOCClient } from '../../../../client';
import { BackupCodesModal } from '../../../../../../lib/components/backup-codes/modal';
import { Toast } from '../../../../../../lib/components/toast';
import { route } from 'preact-router';

import './styles.scss';

interface FormFields {
  username: string;
  password: string;
  passwordConfirm: string;
}

export const DlocCreateCredentials = ({ client }: { client: DLOCClient; path?: string }): JSX.Element => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [formState, setFormState] = useState<FormFields>({ username, password, passwordConfirm });
  const [formFilled, setFormFilled] = useState<boolean>(false);
  const [pwdError, setPwdError] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>('');
  const [showBackupCodesModal, setShowBackupCodesModal] = useState<boolean>(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [registrationError, setRegistrationError] = useState<boolean>(false);
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState<string>('');

  useEffect(() => {
    const loader = document.getElementById('loaderWrapper');
    loader.style.display = 'none';
  }, []);

  useEffect(() => {
    setFormFilled(
      formState.username && formState.username !== '' &&
      formState.password && formState.password !== '' &&
      formState.passwordConfirm && formState.passwordConfirm !== ''
    );
  }, [formState]);

  useEffect(() => {
    if (password && password.length < 8) {
      setPwdError(true);
      setErrorString('Password is too short. Please use at least 8 characters.');
    } else if (password !== passwordConfirm) {
      setPwdError(true);
      setErrorString('Passwords do not match');
    } else {
      setPwdError(false);
      setErrorString('');
    }
  }, [password, passwordConfirm]);

  const registerAccount = async () => {
    const loader = document.getElementById('loaderWrapper');
    loader.style.display = 'flex';

    try {
      try {
        await client.checkPasswordStrength(password);
        setPwdError(false);
      } catch (error) {
        setPwdError(true);
        setErrorString(`<p>The password you chose has been compromised on another website
          (<a href="https://haveibeenpwned.com/Passwords" target="_blank">click here for more details</a>).
          Please choose a new password.</p>`);
        return;
      }
      try {
        await client.registerAccount(username, password);
      } catch (error) {
        setRegistrationErrorMessage((error as Error).message);
        setRegistrationError(true);
        return;
      }
      setShowBackupCodesModal(true);
      setBackupCodes(await client.createBackups(5));
    } finally {
      loader.style.display = 'none';
    }
  };

  return (
    <Fragment>
      <main id="createCredentialsPage">
        <section className="formWrapper">
          <div id="titleWrapper">
            <h1>Create Your Account</h1>
          </div>
          <div id="textWrapper">
            <p>Please carefully review the information below.</p>

            <p><strong><em>If any of this information is missing or incorrect, stop and
              contact <a href="mailto:support@projectcallisto.org">support@projectcallisto.org</a> before
              continuing!</em></strong></p>

            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{client.contactName}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{client.contactEmail}</td>
                </tr>
              </tbody>
            </table>

            <p>If the above information is correct then please enter a username and password below.
              <br />
              <strong><em>Please keep this information in a safe place as Callisto
                is unable to recover forgotten usernames and passwords.</em></strong></p>
          </div>
          <div>
            <input
              className="cred"
              id="username"
              type="text"
              name="username"
              placeholder="Username*"
              autoComplete="false"
              required={true}
              value={username}
              onChange={(evt) => {
                setUsername(evt.currentTarget.value);
                setFormState({
                  ...formState,
                  username: evt.currentTarget.value
                });
              }}
            />

            <input
              className="cred"
              id="password"
              type="password"
              placeholder="Create password 8+ characters*"
              autoComplete="false"
              required={true}
              value={password}
              onChange={(evt) => {
                setPassword(evt.currentTarget.value);
                setFormState({
                  ...formState,
                  password: evt.currentTarget.value
                });
              }}
            />

            <input
              className="cred"
              id="passwordConfirm"
              type="password"
              placeholder="Confirm password*"
              autoComplete="false"
              required={true}
              value={passwordConfirm}
              onChange={(evt) => {
                setPasswordConfirm(evt.currentTarget.value);
                setFormState({
                  ...formState,
                  passwordConfirm: evt.currentTarget.value
                });
              }}
            />

            {pwdError && (
              <div className="pwerror" dangerouslySetInnerHTML={{ __html: errorString }} />
            )}

            <div id="next5" className="show">
              <button className="btn primary" onClick={registerAccount} disabled={!formFilled || pwdError}>
                Create an account
              </button>
            </div>
          </div>
        </section>


        <div id="loaderWrapper">
          <div className="loaderContainer">
            <div className="loader" />
          </div>
        </div>

        {showBackupCodesModal && (
          <BackupCodesModal
            client={client}
            codes={backupCodes}
            onClose={() => {
              setShowBackupCodesModal(false);
              void client.login(username, password)
                .then(() => route('/dashboard'));
            }}
          />
        )}

        {registrationError && (
          <Toast
            error={true}
            message={registrationErrorMessage || 'There was an error registering your account. Please try again.'}
            closeFunc={async () => Promise.resolve(setRegistrationError(false))}
          />
        )}

        { client.token && !registrationError && (
          <Toast
            error={false}
            message={'Invitation verified; please create an account'}
            closeFunc={async () => Promise.resolve()}
          />
        )}

      </main>
    </Fragment>
  );
};
