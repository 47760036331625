/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { DlocClientContext } from '../../components/DlocClientContext';
import {
  useContext,
  useState
} from 'preact/hooks';
import { Toast } from '../../../../../../lib/components/toast';
import { KeyGenModal } from '../../../../../../lib/components/key-generation-modal';

import './styles.scss';
import { useDlocs } from '../../dlocData';
import { NewDlocData } from '../../../../client';
import { DlocInviteModal } from '../../components/dloc-invite-modal';


// eslint-disable-next-line no-empty-pattern
export const DlocAdmin = ({}: { path?: string }): h.JSX.Element => {
  const client = useContext(DlocClientContext);
  const [showKeyGenModal, setShowKeyGenModal] = useState<boolean>(false);
  const [keySuccessfullyGenerated, setKeySuccessfullyGenerated] = useState<boolean>(false);
  const [keyGenerationErrorMessage, setKeyGenerationErrorMessage] = useState<string>('');
  const [dataReencryptedWithErrors, setDataReencryptedWithErrors] = useState<boolean>(false);
  const [dataReencryptionComplete, setDataReencryptionComplete] = useState<boolean>(false);
  const [dataEncryptionErrorMessage, setDataEncryptionErrorMessage] = useState<string>('');

  const { dlocs, inviteNewDloc, activateDloc, refreshDlocs, resendInvitation, isActivated } = useDlocs();
  const [showDlocInviteModal, setShowDlocInviteModal] = useState<boolean>(false);
  const [showNewDlocInvitationEmailSent, setShowNewDlocInvitationEmailSent] = useState<boolean>(false);
  const [isErrorState, setIsErrorState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleGenerateKey = async () => {
    try {
      try {
        const keyGeneratedSuccessfully = await client.generateSharedKey();
        setKeySuccessfullyGenerated(keyGeneratedSuccessfully);
      } catch (error) {
        setKeyGenerationErrorMessage((error as Error).message);
        return;
      }

      try {
        const dataReencryptedSuccessfully = await client.reencryptDataWithLatestKey();
        setKeySuccessfullyGenerated(false); // Necessary for the new toast to be displayed
        setDataReencryptedWithErrors(!dataReencryptedSuccessfully);
        setDataReencryptionComplete(true);
      } catch (error) {
        setDataEncryptionErrorMessage((error as Error).message);
      }
    } finally {
      setShowKeyGenModal(false);
    }
  };

  const activateNewDloc = async (dlocId: string) => {
    try {
      await activateDloc(dlocId);
      refreshDlocs();
    } catch (error) {
      setIsErrorState(true);
      setErrorMessage((error as Error).message);
    }
  };

  const handleInviteNewDloc = async (formData: NewDlocData) => {
    try {
      const response = await inviteNewDloc(formData);
      setShowNewDlocInvitationEmailSent(response);
      setIsErrorState(!response);
      if (!response) {
        setErrorMessage('Error sending invitation');
      } else {
        refreshDlocs();
      }
    } catch (error) {
      setIsErrorState(true);
      setErrorMessage((error as Error).message);
    } finally {
      setShowDlocInviteModal(false);
    }
  };

  const handleResendInvitation = async (dlocId: string) => {
    try {
      const response = await resendInvitation(dlocId);
      setShowNewDlocInvitationEmailSent(response);
      setIsErrorState(!response);
      if (!response) {
        setErrorMessage('Error sending invitation');
      } else {
        refreshDlocs();
      }
    } catch (error) {
      setIsErrorState(true);
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Fragment>
      <div id="loaderWrapper">
        <div className="loaderContainer">
          <div className='loader' />
        </div>
      </div>

      <main id='dlocAdminMain'>
        <div id='dlocAdminCard'>
          <h1>DLOC and Key Administration</h1>
          <button
            className='secondaryButton'
            onClick={() => {
              setShowKeyGenModal(true);
            }}
          >Generate New Shared Key
          </button>

          <h2>All DLOCs</h2>
          {isActivated(client.userID) && (
            <button
              className='primaryButton'
              onClick={() => {
                setShowDlocInviteModal(true);
              }}>Invite New DLOC</button>
          )}
          <div id='dlocTable'>
            <table>
              <tbody>
                <tr id='dlocTableHeader'>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Status/Action</th>
                </tr>
                {
                  dlocs.map((dloc) => (
                    <tr className='record' key={dloc.id}>
                      <td>{dloc.id}</td>
                      <td>{dloc.name}</td>
                      <td>{dloc.email}</td>
                      {dloc.isActivated && dloc.isActive && (
                        <td>Active</td>
                      )}
                      {dloc.isActivated && !dloc.isActive && (
                        <td>Inactive</td>
                      )}
                      {dloc.dateDeactivated && (
                        <td>Deactivated</td>
                      )}
                      {!dloc.isActivated && !dloc.dateDeactivated && dloc.isRegistered && (
                        <td>
                          Needs activation<br />
                          {isActivated(client.userID) && <button
                            className='primary'
                            onClick={() => {
                              void activateNewDloc(dloc.id);
                            }}
                          >Activate</button>}
                        </td>
                      )}
                      {!dloc.isActivated && !dloc.dateDeactivated && !dloc.isRegistered && (
                        <td>
                          Not registered <br />
                          {isActivated(client.userID) && <button
                            onClick={() => {
                              void handleResendInvitation(dloc.id);
                            }}
                          >Resend invitation
                          </button>}
                        </td>
                      )}
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>

      {showKeyGenModal && (
        <KeyGenModal onClose={() => setShowKeyGenModal(false)} onAccept={handleGenerateKey} />
      )}

      {keySuccessfullyGenerated && (
        <Toast
          error={false}
          message="Key successfully generated. Reencrypting data with the new key"
          closeFunc={async () => {
            setKeySuccessfullyGenerated(false);
            return Promise.resolve();
          }}
          timeout={0}
        />
      )}

      {keyGenerationErrorMessage && (
        <Toast
          error={true}
          message={keyGenerationErrorMessage}
          closeFunc={async () => {
            setKeyGenerationErrorMessage('');
            return Promise.resolve();
          }}
          timeout={0}
        />
      )}

      {(dataReencryptionComplete && dataReencryptedWithErrors) && (
        <Toast
          error={true}
          message="Data reencrypted with errors. See logs for details."
          closeFunc={async () => {
            setDataReencryptionComplete(false);
            return Promise.resolve();
          }}
          timeout={0}
        />
      )}

      {(dataReencryptionComplete && !dataReencryptedWithErrors) && (
        <Toast
          error={false}
          message="Data reencryption successfully completed"
          closeFunc={async () => {
            setDataReencryptionComplete(false);
            return Promise.resolve();
          }}
          timeout={10000}
        />
      )}

      {dataEncryptionErrorMessage && (
        <Toast
          error={true}
          message={dataEncryptionErrorMessage}
          closeFunc={async () => {
            setDataEncryptionErrorMessage('');
            return Promise.resolve();
          }}
          timeout={0}
        />
      )}

      {showDlocInviteModal && (
        <DlocInviteModal
          onClose={() => { setShowDlocInviteModal(false); }}
          onInvite={(formData) => { void handleInviteNewDloc(formData); }}
        />
      )}

      {showNewDlocInvitationEmailSent && (
        <Toast
          error={false}
          message="Invitation email sent"
          closeFunc={async () => Promise.resolve(setShowNewDlocInvitationEmailSent(false))}
        />
      )}

      {isErrorState && (
        <Toast
          error={true}
          message={errorMessage}
          closeFunc={async () => Promise.resolve(setIsErrorState(false))}
        />
      )}
    </Fragment>
  );
};
