/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { useCases } from '../dlocData';
import { Modal } from '../../../../../lib/components/modal';
import { ActionTimer } from '../../../../../lib/components/ActionTimer';
import { SubmitCancelReturn } from '../../../../../lib/components/SubmitCancelReturn';
import { Toast } from '../../../../../lib/components/toast';
import { dlocEntry } from '../../../../../lib/data';

export const ViewEditNotes = ({
  onClose,
  entry,
  onSave
}: {
  onClose: () => void;
  entry: dlocEntry;
  onSave: (text: string) => void;
}): JSX.Element => {
  const [notesText, setNotesText] = useState<string>(entry.notes ?? undefined);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [showSubmitCancelReturn, setShowSubmitCancelReturn] = useState<boolean>(false);
  const { saveNotes } = useCases();

  const handleSave = async () => {
    await saveNotes(entry.id, notesText);
    onSave(notesText);
    entry.notes = notesText;
    setSaveSuccessful(true);
    setChangesMade(false);
  };

  return (
    <Modal
      minWidth="95%"
      closeFunc={async () => {
        onClose();
        return Promise.resolve();
      }}
      canExit={false}
    >
      <ActionTimer
        timeoutInMinutes={25}
        onTimeout={async () => {
          if (changesMade) {
            await handleSave();
          }
        }}
      >
        <h1>Case Notes</h1>
        <p><b>Case ID:</b> {entry.id}</p>

        <h2>Notes</h2>
        <p><textarea
          value={notesText}
          disabled={showSubmitCancelReturn}
          style={{ width: '100%', height: '400px', fontSize: '12pt', color: '#225a75' }}
          onChange={(e) => {
            setNotesText(e.currentTarget.value);
            if (!changesMade) {
              setChangesMade(true);
            }
          }}
        /></p>

        {showSubmitCancelReturn && (
          <SubmitCancelReturn
            submitText="Save and Close"
            cancelText="Discard Changes"
            returnText="Return to Notes"
            headerText="You have unsaved changes. If you close without saving, you will lose the changes."
            onReturn={() => {
              setShowSubmitCancelReturn(false);
            }}
            onCancel={onClose}
            onSubmit={async () => {
              await handleSave();
              onClose();
            }}
          />
        )}

        {!showSubmitCancelReturn && (
          <div>
            <button
              className="secondaryButton rightAligned"
              onClick={() => {
                if (changesMade) {
                  setShowSubmitCancelReturn(true);
                } else {
                  onClose();
                }
              }}
            >Close</button>
            <button
              className="primaryButton saveButton rightAligned"
              disabled={!changesMade}
              onClick={handleSave}
            >Save</button>
            {saveSuccessful && (
              <Toast
                error={false}
                message="Notes saved successfully"
                closeFunc={async () => {
                  setSaveSuccessful(false);
                  return Promise.resolve();
                }}
              />
            )}
          </div>
        )}
      </ActionTimer>
    </Modal>
  );
};
